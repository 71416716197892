<template>
  <div class="app-main-content">
    <card :tabs="sort_project_bstatus" v-model="params.business_status" @change="tabsChange">
      <template #tools>
        <div class="line" style="margin-left: -3px">
          <a-button type="primary" @click="handleCreatePro" style="margin-right: 10px;">
            <i class="iconfont icon-jia" style="margin-right: 4px;"></i>
            新建项目
          </a-button>
          <div class="count">
            共
            <span>{{ projectList.length }}</span>
            个
          </div>
        </div>
        <div class="right">
          <div class="item">
            <span class="label">排序</span>
            <a-select @change="getList" class="sort-select" placeholder="请选择"
                      v-model="params.orderby">
              <a-select-option value="">全部</a-select-option>
              <a-select-option value="schedule_end_date desc">计划完成倒序</a-select-option>
              <a-select-option value="schedule_end_date asc">计划完成正序</a-select-option>
              <a-select-option value="business_status desc">项目状态倒序</a-select-option>
              <a-select-option value="business_status asc">项目状态正序</a-select-option>
            </a-select>
          </div>
          <div class="item">
            <span class="label">搜索</span>
            <a-input class="search-input" v-model="params.keywords" placeholder="编号、名称">
              <a-icon slot="prefix" type="search" />
            </a-input>
          </div>
          <a-button class="search" @click="getList">查询</a-button>
        </div>
      </template>
      <div class="scrollY" style="min-width: 1400px;max-width: 1600px;">
        <a-table :data-source="projectList" bordered :pagination="false" rowKey="oid">
          <a-table-column title="项目编号" data-index="project_code" width="110px">
            <template slot-scope="scope, row">
              <edp-input
                  style="width: 100%"
                  size="mini"
                  v-model="row.project_code"
                  :db="dbs.prjCore"
                  field-name="project_code"
                  :oid="row.oid"
                  :prj-oid="row.oid"
                  @change="getList"
              ></edp-input>
            </template>
          </a-table-column>
          <a-table-column title="项目名称" data-index="project_name">
            <template slot-scope="scope, row, $index">
              <div class="edp-table-name">
                <!--                <a-input ref="table-name" v-if="row.editName" v-model="row.project_name"-->
                <!--                         @blur="fieldUpdate(row, $index)"></a-input>-->
                <span style="color: #08f;" v-if="row.is_template === '1'">【模版】</span>
                <edp-input
                    ref="table-name"
                    v-if="row.editName"
                    :db="dbs.prjCore"
                    :prj-oid="row.oid"
                    :oid="row.oid"
                    v-model="row.project_name"
                    field-name="project_name"
                    @blur="reload($index)"
                    @change="getList"
                ></edp-input>
                <router-link style="color: #08f;" v-else :to="{path: '/project', query: {prj_oid: row.oid}}">{{
                    scope
                  }}
                </router-link>
                <i v-if="!row.editName" class="iconfont icon-wenbenshuru" @click="clickFoucs($index)"></i>
              </div>
            </template>
          </a-table-column>
          <a-table-column title="项目状态" data-index="business_status" width="110px">
            <template slot-scope="scope, row">
              <edp-select
                  :skin="`status ${row.business_status === '100' ? 'yellow' : row.business_status === '500' ? 'green' : row.business_status === '1000' ? 'gray' : 'blue'}`"
                  v-model="row.business_status"
                  :db="dbs.prjCore"
                  field-name="business_status"
                  :oid="row.oid"
                  :prj-oid="row.oid"
                  :options="sort_project_bstatus"
                  options-type="cat"
              ></edp-select>
            </template>
          </a-table-column>
          <a-table-column title="项目负责人" width="140px">
            <template slot-scope="row">
              <edp-select
                  style="width: 100%;"
                  v-model="row.project_admin"
                  :db="dbs.prjCore"
                  field-name="project_admin"
                  :oid="row.oid"
                  :prj-oid="row.oid"
                  :options="getAccountOption(row.members ?? [])"
              >
              </edp-select>
            </template>
          </a-table-column>
          <a-table-column title="项目完成进度" data-index="compelete_percent" width="190px">
            <template slot-scope="scope">
              <a-progress :percent="scope - 0" strokeColor="#24B47E" size="small" />
            </template>
          </a-table-column>
          <a-table-column title="项目年份" data-index="project_year" width="130px">
            <template slot-scope="scope, row">
              <edp-year-picker
                  style="width: 100%;"
                  v-model="row.project_year"
                  :db="dbs.prjCore"
                  field-name="project_year"
                  :prj-oid="row.oid"
                  :oid="row.oid"
              />
            </template>
          </a-table-column>
          <a-table-column title="计划结束" data-index="schedule_end_date" width="140px">
            <template slot-scope="scope, row">
              <edp-date-picker
                  style="width: 100%;"
                  v-model="row.schedule_end_date"
                  :db="dbs.prjCore"
                  field-name="schedule_end_date"
                  :prj-oid="row.oid"
                  :oid="row.oid"
              ></edp-date-picker>
            </template>
          </a-table-column>
          <a-table-column title="交付物" width="90px">
            <template slot-scope="scope">
              <span>{{ scope.delivery_finish }}/{{ scope.delivery_totalcount }}</span>
            </template>
          </a-table-column>
          <a-table-column title="成员" width="170px">
            <template #default="_, row">
              内部：{{ row.internal_count ?? 0 }}，外部：{{ row.external_count ?? 0 }}
            </template>
          </a-table-column>
        </a-table>
      </div>
      <create-prj-modal ref="modal" @reload="createCallback"></create-prj-modal>
    </card>
  </div>
</template>

<script>
import { fetch } from "@/utils/request.js";
import catMixin from "@/utils/mixins/catMixin";
import { FieldUpdateDb } from "@/utils/FieldUpdate.js";
import EdpYearPicker from "@/components/e-form/edp-year-picker.vue";
import EdpInput from "@/components/e-form/edp-input.vue";
import EdpSelect from "@/components/e-form/edp-select.vue";
import EdpDatePicker from "@/components/e-form/edp-date-picker.vue";
import CreatePrjModal from "@/components/createPrjModal.vue";

export default {
  name: "projectList",
  mixins: [catMixin],
  props: {
    default_status: {
      type: String,
      default: "100"
    }
  },
  data() {
    return {
      dbs: {
        prjCore: new FieldUpdateDb("prj", "prj_core")
      },
      params: {
        keywords: '',
        business_status: this.$route.query.default_status ?? this.default_status,
        orderby: ''
      },
      projectList: [],
      tempList: [],
      visible: false,
      form: this.$form.createForm(this, { name: 'pro_form' }),
      formRule: {
        project_name: {
          rules: [
            { required: true, message: '请输入项目名称', }
          ]
        }
      },
      memberSearch: '',
      checked: [],
      newChecked: [],
      oid: '', //前端生成oid
      cat: {
        project_bstatus: []
      },
      accountList: [],
      company_info: {}
    }
  },
  components: {
    CreatePrjModal,
    EdpDatePicker,
    EdpSelect,
    EdpInput,
    EdpYearPicker
  },
  computed: {
    sort_project_bstatus() {
      let cloneBstatus = JSON.parse(JSON.stringify(this.cat.project_bstatus))
      return cloneBstatus.sort((a, b) => {
        if (a.cat_name === '已归档') {
          return 1
        } else if (a.cat_name === '已完成' && b.cat_name !== '已归档') {
          return 1
        } else if (a.cat_name === '已暂停' && b.cat_name !== '已完成') {
          return 1
        } else if (a.cat_name === '进行中' && b.cat_name === '未开始') {
          return 1
        } else {
          return -1
        }
      })
    },
  },
  created() {
    this.company_info = this.storage.getItem('company_info')
    let { business_status } = this.$route.query
    if (business_status) this.params.business_status = business_status
  },
  mounted() {
    this.getList()
    this.getTempList()
  },
  methods: {
    getAccountOption(list) {
      return list.map(item => {
        return {
          value: item.oid,
          label: item.title ? item.name + "-" + item.title : item.name,
          key: item.oid
        }
      })
    },
    getList() {
      let url = window.mock ? 'http://127.0.0.1:4523/m1/2234408-0-default/prj/retrieve' : '/prj/retrieve'
      fetch('post', url, this.params).then((res) => {
        if (res.status === 0) {
          let data = res.data.datas
          if (data.length > 0) {
            data = data.map(item => {
              return {
                ...item,
                editName: false
              }
            })
            this.projectList = data
          } else {
            this.projectList = []
          }
        }
      })
    },
    handleCreatePro() {
      this.$refs.modal.open()
    },
    createCallback() {
      this.params.business_status = "0"
      this.getList()
    },
    tabsChange() {
      this.getList()
    },
    fieldUpdate(row, index) {
      console.log(row);
      this.projectList[index].editName = false
      this.$updateOnefield('prj', 'prj_core', 'project_name', row.project_name, row.oid, row.oid)
      this.editFolderName(row.project_name, row.oid)
    },
    clickFoucs(index) {
      // console.log(index);
      this.$set(this.projectList[index], 'editName', true)
      this.$nextTick(() => {
        // console.log(this.$refs["table-name"]);
        this.$refs["table-name"].$children[0].focus()
      })
    },
    reload(index) {
      this.$set(this.projectList[index], 'editName', false)
    },
    deselectMembers(user_oid, row) {
      console.log('取消', user_oid, row);
      fetch('post', '/prj/userlist/delete', {
        prjoid: row.oid,
        user_oid
      }).then((res) => {
        if (res.status === 0) {
          this.$message.success('刪除成功！')
        }
      })
    },
    selectMembers(user_oid, row) {
      console.log('选中', user_oid, row);
      fetch('post', '/prj/userlist/create', {
        prjoid: row.oid,
        user_oid
      }).then((res) => {
        if (res.status === 0) {
          this.$message.success('新增成功！')
        }
      })
    },
    editFolderName(folder_name, prjoid) {
      fetch('post', '/prj/folder/update', {
        folder_name,
        prjoid
      }).then((res) => {
        if (res.status === 0) {
          console.log(res.data);
        }
      })
    },
    onChange(checkedList) {
      // console.log(checkedList);
      // console.log(this.searchItems);
      if (checkedList.length > 0) { // 选取有值时
        checkedList.forEach(e1 => {
          if (!this.newChecked.includes(e1)) { // 总选中数组遍历没有的 push进去
            this.newChecked.push(e1)
          }
        })
      }
      this.searchItems.forEach(e1 => { // 遍历搜索出来的所有项
        if (!checkedList.includes(e1.value)) { // 当前选中的有没有搜索出来的值
          console.log(e1.username);
          let index = this.newChecked.indexOf(e1.value)
          if (index !== -1) this.newChecked.splice(index, 1) // 没有就进行删除
        }
      })
      this.checked = this.newChecked
    },
    getTempList() {
      fetch('post', '/prj/retrieve', {
        keywords: '',
        business_status: null,
        orderby: '',
        is_template: '1'
      }).then((res) => {
        if (res.status === 0) {
          this.tempList = res.data.datas
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
/deep/ .edp-card {
  .edp-card-head-left-tabs {
    margin-left: -12px;
  }
}

.common_detail_body {

  .detail_body_head {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    box-sizing: content-box;
    //border-bottom: 1px solid #e8e8e8;
    padding: 0 38px 0 20px;
    height: 51px;
    flex-shrink: 0;

    .left {
      display: flex;
      align-items: center;

      .count {
        color: #4D4D4D;
        margin-left: 11px;
      }
    }

    .right {
      display: flex;
      align-items: center;

      .ant-dropdown-link {
        width: 32px;
        height: 32px;
        border-radius: 4px 4px 4px 4px;
        background: rgba(243, 243, 243, 1);
        color: #3D3D3D;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 15px;
      }
    }
  }

  /deep/ .detail_body_content {
    overflow-x: auto;
  }
}
</style>
