<template>
  <a-date-picker
    @panelChange="panelChange"
    @change="updateField"
    :class="`edp-date-picker edp-date-picker-size-${size}`"
    v-bind="$attrs"
    v-model="tempValue"
    :valueFormat="valueFormat"
    :format="format"
    @openChange="(...attrs) => $emit('openChange', ...attrs)"
  >

  </a-date-picker>
</template>

<script>
import edpFormMixins from "@/components/e-form/edpFormMixins.js";

export default {
  mixins: [edpFormMixins],
  props: {
    size: {
      type: String,
      validator: val => ['mini', 'middle', 'big', 'purchase-mini'].includes(val),
      default: () => "middle"
    },
    format: {
      type: String,
      default: () => "YYYY/MM/DD"
    }
  },
  computed: {
    valueFormat() {
      return this.format
    }
  },
  methods: {
    panelChange(val) {
      console.log(val)
    }
  }
}
</script>

<style lang="less" scoped>
.edp-date-picker {
  line-height: 1;
  width: 200px;
  &-size-mini {
    --height: 24px;
  }
  &-size-purchase-mini {
    width: 100%;
    --height: 28px;
  }
  /deep/ .ant-calendar-picker-input {
    height: var(--height, 32px);
    border-color: rgba(0,0,0,0);
    padding-left: 0;
    padding-top: 0;
    padding-bottom: 0;
    color: #303030;
    font-size: 14px;
    &:hover {
      padding-left: 8px;
    }
  }
  /deep/ .anticon-calendar {
    display: none;
  }
}
</style>